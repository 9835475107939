<template>
  <div>
    <GlobalInfoBar title="门店列表"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询">
<!--       <div style="display: flex" slot="AddSlot" >-->
<!--         <el-button type="primary" @click="addStore" size="small"  style="margin-right: 20px" icon="el-icon-plus">添加门店</el-button>-->

<!--         <el-popover-->
<!--             placement="right"-->
<!--             width="400"-->
<!--             trigger="click">-->
<!--           <div class="note" style="color: red;margin-bottom: 20px">温馨提示:请按模板表格严格填写，带*的为必填项</div>-->
<!--           <el-button size="small" type="warning" style="margin-bottom: 20px" @click="downloadTemplate">下载数据模板</el-button>-->

<!--           <el-upload-->
<!--               ref="upload"-->
<!--               action="#"-->
<!--               :limit="1"-->
<!--               :auto-upload="false"-->
<!--               :on-change="getFile"-->
<!--               :file-list="fileList">-->
<!--             <el-button size="small" type="primary">点击上传</el-button>-->
<!--             <div slot="tip" class="el-upload__tip">仅支持.xls及.xlsx格式,单次上传大小不超过5M</div>-->
<!--           </el-upload>-->
<!--           <el-button size="small" type="primary" slot="reference">批量上传</el-button>-->
<!--         </el-popover>-->

<!--       </div>-->
      </GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="省市区" slot="region" align="center">
          <template slot-scope="{ row }">
            {{ row.province_name }}/{{ row.city_name }}/{{ row.district_name }}
          </template>
        </el-table-column>
        <el-table-column label="坐标" slot="coordinate" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.lon&&row.lat">{{ row.lon }},{{ row.lat }}</span>
            <span v-else>--</span>
<!--            <a v-if="row.lon&&row.lat" target="_blank"-->
<!--               :href="`https://www.amap.com/?p=B001C8TY1F,${row.lon},${row.lat}`">{{ row.lon }},{{ row.lat }}</a>-->
<!--            <a v-else href="javascript:" @click="genCoordinate(row)">点击生成</a>-->
          </template>
        </el-table-column>

<!--        <el-table-column label="操作" slot="operation" align="center">-->
<!--          <template slot-scope="{ row }">-->
<!--            <el-button type="primary" size="mini" @click="editRow(row)" style="margin-right: 20px">编辑</el-button>-->

<!--            <el-popover-->
<!--                placement="left"-->
<!--                width="300"-->
<!--                v-model="row.isShowPopover">-->
<!--              <p style="color:red"><span class="el-icon-warning" style="margin-right: 10px"></span>删除后该门店不可作为线下焕新门店</p>-->
<!--              <p style="width: 100%;text-align: center;margin-top: 15px">确认删除该门店？</p>-->
<!--              <div style="margin-top: 20px">-->
<!--                <el-button size="small" plain @click="row.isShowPopover = false">取消</el-button>-->
<!--                <el-button type="primary" size="small" @click="row.isShowPopover = false;deleteRow(row)">确定</el-button>-->
<!--              </div>-->
<!--              <el-button type="danger" size="mini"  slot="reference">删除</el-button>-->
<!--            </el-popover>-->

<!--          </template>-->
<!--        </el-table-column>-->
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>

import {addEditStore, importStore, storeHandle, storeList} from "../../../utils/request/changePhone";
import axios from 'axios'
import environments from "../../../config/url";

export default {
  name: "index",
  data() {
    return {
      formItemList: [{
        key: 'store_name',
        type: 'input',
        labelName: '门店名称:',
        placeholder: '请输入门店名称'
      }, {
        key: 'store_phone',
        type: 'input',
        labelName: '联系电话:',
        placeholder: '请输入联系电话'
      }],
      store_name: '',
      store_phone: '',
      tableColumns: [
        {label: "所属渠道", prop: "pass_name"},
        {label: "门店名称", prop: "store_name"},
        {label: "所属代理商", prop: "agent_name"},
        {label: "联系电话", prop: "store_phone"},
        {slotName: "region"},
        {label: "详细地址", prop: "address"},
        {slotName: "coordinate"},
        // {slotName: "operation"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      fileList:[]
    };
  },
  mounted() {
    this.getList()
  },
  methods: {

    currentChange(page) {
      this.curPage = page
      this.getList()
    },
    query(data) {
      this.store_name = data.store_name
      this.store_phone = data.store_phone
      this.getList();
    },
    editRow(row) {
      this.$router.push({
        path: 'storeOperation',
        query: {store_id: row.store_id}
      })
    },
    deleteRow(row) {
      storeHandle({
        store_id: row.store_id,
        type: 2,
      }).then(data => {
        this.getList()
        this.$message.success(data && data.msg || '删除成功')
      }).catch(err => {
        console.error(err)
      })
    },
    genCoordinate(row) {
      let key = process.env.VUE_APP_MAP_KEY
      let address = `${row.province_name}${row.city_name}${row.district_name}${row.address}`
      axios.get(`http://restapi.amap.com/v3/geocode/geo?key=${key}&s=rsv3&city=35&address=${address}`).then(res => {
        console.log(res.data, '得到经纬度')
        let location = res && res.data && res.data.geocodes && res.data.geocodes[0].location || ''
        let lon = location.split(',')[0]
        let lat = location.split(',')[1]
        storeHandle({
          store_id: row.store_id,
          type: 1,
          lon, lat
        }).then(data => {
          this.getList()
          this.$message.success(data && data.msg || '生成成功')
        }).catch(err => {
          console.error(err)
        })
      }).catch(err => {
        this.$message.error('无法获取坐标，请核实位置信息')
      })
    },
    getList() {
      storeList({
        page: this.curPage,
        limit: 10,
        store_name: this.store_name,
        store_phone: this.store_phone
      }).then(res => {
        this.tableData = res.data || [];
        this.totalElements = res.count || 0
      })
    },
    addStore() {
      this.$router.push({
        path: 'storeOperation'
      })
    },
    downloadTemplate() {
      window.location.href=`${environments[process.env.VUE_APP_BASE_URL].baseURL}/Excel/downloadStore`
    },
    getFile(file) {
      let form=new FormData()
      form.append('file',file.raw)
      importStore(form).then(data => {
        this.getList()
        this.$message.success(data && data.msg || '导入成功')
      }).catch(err => {
        console.error(err)
      }).finally(()=>{
        this.$refs.upload.clearFiles()
      })
    }
  }
}
</script>

<style scoped>

</style>
